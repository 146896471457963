<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
  },
  data() {
    return {
      HrEmployeeKpiRecords: [],
      employee: {},
      hr_department_kpi: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchHrEmployeeKpiRecords: null,
      HrEmployeeKpiRecordsSearchMode: false,
      searchModel: "",
      state: state,
    };
  },
  methods: {
    editHrEmployeeKpiRecords(app) {
      let dateObj = `${app?.year}-${app?.month}`;
      var data = {
        title: "popups.edit",
        inputs: [
          { model: "value", type: "text", label: "value", value: app.value },
          {
            model: "month",
            type: "month",
            label: "Year and Month",
            value: dateObj,
          },
          // { model: "year", type: "text", label: "year", value: app.year },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.hr_employee_id = app?.hr_employee?.id;
              obj.year = obj?.month.split("-")[0];
              obj.month = obj?.month.split("-")[1];
              console.log(obj);
              this.http.put("hr-employee-kpi-records", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    getHrEmployeeKpiRecords() {
      this.http.get("hr-employee-kpi-records").then((res) => {
        this.HrEmployeeKpiRecords = res.data;
      });
    },
    deleteHrEmployeeKpiRecords(app) {
      var data = {
        title: "popups.delete",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          this.http.delete("hr-employee-kpi-records", app.id).then(() => {
            this.get(this.page);
          });
        }
      });
    },
    get(page) {
      console.log(page);
      this.http
        .post("hr-employee-kpi-records/pagination", {
          limit: this.limit,
          page: page,
          id: parseInt(this.$route.params.id),
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.HrEmployeeKpiRecords = res.data;
          this.HrEmployeeKpiRecords.forEach((record) => {
            if (record?.month.toString().length == 1) {
              record.month = `0${record?.month}`;
            }
          });
          this.employee = res.data[0]?.hr_employee;
        });
    },
  },
  created() {
    this.get(1);
  },
};
</script>

<template>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="
        `${employee?.name} ` +
        $t('menu.menuitems.hr.subItems.performance_management_profile')
      "
    />
    <div class="row mb-4">
      <div class="col-6">
        <div class="search-box chat-search-box w-50">
          <div class="position-relative">
            <input
              @keyup="search()"
              type="text"
              class="form-control fa-lg text-light"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              :placeholder="$t('popups.search')"
              v-model="searchModel"
              style="background-color: #2a3042 !important"
            />
            <i class="bx bx-search-alt search-icon text-light"></i>
          </div>
        </div>
      </div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button
          class="btn-close text-light"
          v-if="HrEmployeeKpiRecordsSearchMode"
          @click="cancelappsearchMode()"
        ></button>
      </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr
          class="text-light text-center"
          style="background-color: #2a3042 !important"
        >
          <th scope="col">{{ $t("hr_employee_kpi_records.id") }}</th>
          <th scope="col">
            {{ $t("hr_employee_kpi_records.hr_department_kpi_id") }}
          </th>
          <th scope="col">{{ $t("hr_employee_kpi_records.value") }}</th>
          <th scope="col">{{ $t("hr_employee_kpi_records.month") }}</th>
          <th scope="col">{{ $t("hr_employee_kpi_records.year") }}</th>
          <th scope="col">{{ $t("hr_employee_kpi_records.created") }}</th>
          <th scope="col">{{ $t("hr_employee_kpi_records.updated") }}</th>
          <th scope="col">{{ $t("hr_employee_kpi_records.operations") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="app in HrEmployeeKpiRecords" :key="app" class="text-center">
          <td>{{ app.id }}</td>
          <td>{{ app?.hr_department_kpi?.weight }}</td>
          <td>{{ app?.value }}</td>
          <td>{{ app?.month }}</td>
          <td>{{ app?.year }}</td>
          <td>{{ app.created.split("T")[0] }}</td>
          <td>{{ app.updated.split("T")[0] }}</td>

          <td class="d-flex">
            <a
              class="btn btn-primary mx-1"
              @click="editHrEmployeeKpiRecords(app)"
              href="javascript: void(0);"
              role="button"
              >{{ $t("popups.edit") }}</a
            >
            <a
              class="btn btn-danger"
              href="javascript: void(0);"
              role="button"
              @click="deleteHrEmployeeKpiRecords(app)"
              >{{ $t("popups.delete") }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <div v-if="tot_pages == 20">
      <!--   Apps  Pagination     -->
      <ul class="pagination pagination-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ active: p == page }"
          v-for="p in tot_pages"
          :key="p"
        >
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == total_pages }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page + 1)"
            aria-label="Next"
          >
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!--end-->
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
